import React from 'react';
import { Star } from 'lucide-react';
import image3 from "../../images/heroimages/p7.jpeg"


const testimonial = {
  id: 1,
  stars: 5,
  quote: "After completing my MSc in Aerospace Engineering at the University of Bath, I was determined to launch my career in the UK's cutting-edge aerospace industry. However, navigating the job market and visa requirements seemed overwhelming. UKWorkx's expertise was invaluable. They helped me showcase my technical skills, connect with aerospace companies, and navigate the complex sponsorship process. Thanks to their support, I landed a dream role at a leading aerospace firm. UKWorkx truly helped me soar to new heights in my career!",
  author: 'Pratik',
  jobTitle: 'Aerospace Design Engineer, Bristol',
  avatarUrl: '/api/placeholder/100/100',
  linkedIn: 'https://www.linkedin.com/in/pratik-sharma-aerospace/',
};

const caseStudyContent = [
  {
    title: "Background",
    content: "Pratik, an Indian national, completed his MSc in Aerospace Engineering at the University of Bath in September 2023. With a passion for innovative aircraft design and a strong academic background, he aimed to secure a position in the UK's competitive aerospace industry while navigating the complex visa sponsorship process."
  },
  {
    title: "Challenges",
    content: [
      "Highly specialized field with limited openings for international graduates",
      "Need for a role offering Skilled Worker visa sponsorship in the aerospace sector",
      "Competing with local graduates and experienced professionals",
      "Limited industry network in the UK aerospace community",
      "Balancing job search with completing a challenging Masters project",
      "Adapting academic knowledge to meet industry-specific requirements"
    ]
  },
  {
    title: "Solution",
    content: [
      "Comprehensive review and optimization of CV and technical portfolio",
      "Targeted search for graduate positions in aerospace design and engineering",
      "Interview and technical assessment preparation specific to aerospace roles",
      "Networking strategies within the UK aerospace industry",
      "Guidance on visa sponsorship for engineering roles and UK aerospace regulations",
      "Support in translating academic projects into industry-relevant experiences"
    ],
    intro: "In July 2023, Pratik enrolled in our UKWorkx Elite Plan, tailored for international graduates. This £799 plan included:"
  },
  {
    title: "Implementation",
    content: [
      "Conducted a thorough review of Pratik's CV, highlighting key aerospace projects and technical skills",
      "Identified UK-based aerospace companies and defense contractors with sponsorship licenses",
      "Provided intensive preparation for technical interviews and design assessments",
      "Arranged introductions to alumni and industry professionals in the UK aerospace sector",
      "Offered guidance on UK aerospace industry standards and certification processes",
      "Helped create a portfolio showcasing Pratik's CAD designs and simulation projects"
    ],
    intro: "Our team worked closely with Pratik to:"
  },
  {
    title: "Results",
    content: [
      "Pratik secured an Aerospace Design Engineer position in Bristol in November 2023",
      "The role came with full Skilled Worker visa sponsorship",
      "He received interest from multiple aerospace firms, including both civil and defense sectors",
      "Successfully transitioned from his student visa to a Skilled Worker visa",
      "Started his UK aerospace career within 2 months of graduation",
      "Gained a position that allows him to work on next-generation aircraft designs"
    ],
    intro: "Through our specialized approach and Pratik's dedication:"
  }
];

function CaseStudyPratik() {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">
        Success Story: From Aerospace Engineering Graduate to Sponsored Design Engineer in Bristol
      </h1>

      {caseStudyContent.map((section, index) => (
        <div key={index} className="mb-8 bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
            <h2 className="text-2xl font-semibold">{section.title}</h2>
          </div>
          <div className="px-6 py-4">
            {section.intro && <p className="mb-2">{section.intro}</p>}
            {Array.isArray(section.content) ? (
              <ul className="list-disc pl-5 space-y-2">
                {section.content.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{section.content}</p>
            )}
          </div>
        </div>
      ))}

      <div className="mt-12 bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <h2 className="text-2xl font-semibold text-center">Client Testimonial</h2>
        </div>
        <div className="px-6 py-4">
          <div className="flex justify-center mb-4">
            {[...Array(testimonial.stars)].map((_, index) => (
              <Star key={index} className="h-6 w-6 text-yellow-400 fill-current" />
            ))}
          </div>
          <blockquote className="text-center text-gray-700 mb-6">
            "{testimonial.quote}"
          </blockquote>
          <div className="flex items-center justify-center">
            <img
              className="h-12 w-12 rounded-full mr-4"
              src={image3}
              alt={testimonial.author}
            />
            <div>
              <p className="font-semibold">{testimonial.author}</p>
              <p className="text-gray-600">{testimonial.jobTitle}</p>
              <p className="text-blue-600 hover:underline"
              >
                Client refused to provide LinkedIn
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudyPratik;