import React from 'react';

function FeatureList() {
  return (
    <div>
      <ul className="mt-8 space-y-3 font-medium">
        <li className="flex items-start lg:col-span-1">
          <div className="flex-shrink-0">
            <svg className="w-5 h-5 text-indigo-600" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <p className="ml-3 leading-5 text-gray-600">Our tool offers a job board with 117,000 licensed visa sponsors and over 400 jobs posted daily. It generates a tailored, ATS-friendly resume and cover letter specifically for each job in under a minute.</p>
        </li>
        <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
          <div className="flex-shrink-0">
            <svg className="w-5 h-5 text-indigo-600" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <p className="ml-3 leading-5 text-gray-600">
            We apply to jobs on your behalf with Personalised CVs and Cover Letters.
          </p>
        </li>
        <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
          <div className="flex-shrink-0">
            <svg className="w-5 h-5 text-indigo-600" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <p className="ml-3 leading-5 text-gray-600">
          93% client satisfaction rate.
          </p>
        </li>
        <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
          <div className="flex-shrink-0">
            <svg className="w-5 h-5 text-indigo-600" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <p className="ml-3 leading-5 text-gray-600">Interview preparation and mock tests</p>
        </li>
        <li className="flex items-start mt-5 lg:col-span-1 lg:mt-0">
          <div className="flex-shrink-0">
            <svg className="w-5 h-5 text-indigo-600" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <p className="ml-3 leading-5 text-gray-600">
            Bi-weekly meetings for progress tracking and support
          </p>
        </li>
      </ul>
    </div>
  );
}

export default FeatureList;