import { useState } from "react";
import { motion } from "framer-motion";
import {
  SiAtlassian,
  SiDribbble,
  SiGrubhub,
  SiKaggle,
  SiSlack,
  SiNike,
} from "react-icons/si";

const StackedCardTestimonials = () => {
  const [selected, setSelected] = useState(0);

  return (
    <section className="bg-white py-24 px-4 lg:px-8 grid items-center grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-4 overflow-hidden">
      <div className="p-4">
        <h3 className="text-5xl font-semibold">What our clients think</h3>
        <p className="text-slate-500 my-4">
          See where our clients are working now and what they have to say about us.
        </p>
        <SelectBtns
          numTracks={testimonials.length}
          setSelected={setSelected}
          selected={selected}
        />
      </div>
      <Cards
        testimonials={testimonials}
        setSelected={setSelected}
        selected={selected}
      />
    </section>
  );
};

const SelectBtns = ({ numTracks, setSelected, selected }) => {
  return (
    <div className="flex gap-1 mt-8">
      {Array.from(Array(numTracks).keys()).map((n) => {
        return (
          <button
            key={n}
            onClick={() => setSelected(n)}
            className="h-1.5 w-full bg-slate-300 relative"
          >
            {selected === n ? (
              <motion.span
                className="absolute top-0 left-0 bottom-0 bg-slate-950"
                initial={{
                  width: "0%",
                }}
                animate={{
                  width: "100%",
                }}
                transition={{
                  duration: 5,
                }}
                onAnimationComplete={() => {
                  setSelected(selected === numTracks - 1 ? 0 : selected + 1);
                }}
              />
            ) : (
              <span
                className="absolute top-0 left-0 bottom-0 bg-slate-950"
                style={{
                  width: selected > n ? "100%" : "0%",
                }}
              />
            )}
          </button>
        );
      })}
    </div>
  );
};

const Cards = ({ testimonials, selected, setSelected }) => {
  return (
    <div className="p-4 relative h-[450px] lg:h-[500px] shadow-xl">
      {testimonials.map((t, i) => {
        return (
          <Card
            {...t}
            key={i}
            position={i}
            selected={selected}
            setSelected={setSelected}
          />
        );
      })}
    </div>
  );
};

const Card = ({
  Icon,
  description,
  name,
  title,
  position,
  selected,
  setSelected,
}) => {
  const scale = position <= selected ? 1 : 1 + 0.015 * (position - selected);
  const offset = position <= selected ? 0 : 95 + (position - selected) * 3;
  const background = position % 2 ? "black" : "white";
  const color = position % 2 ? "white" : "black";

  return (
    <motion.div
      initial={false}
      style={{
        zIndex: position,
        transformOrigin: "left bottom",
        background,
        color,
      }}
      animate={{
        x: `${offset}%`,
        scale,
      }}
      whileHover={{
        translateX: position === selected ? 0 : -3,
      }}
      transition={{
        duration: 0.25,
        ease: "easeOut",
      }}
      onClick={() => setSelected(position)}
      className="absolute top-0 left-0 w-full min-h-full p-8 lg:p-12 cursor-pointer flex flex-col justify-between"
    >
      <Icon className="text-7xl mx-auto" />
      <p className="text-lg lg:text-xl font-light italic my-8">
        "{description}"
      </p>
      <div>
        <span className="block font-semibold text-lg">{name}</span>
        <span className="block text-sm">{title}</span>
      </div>
    </motion.div>
  );
};

export default StackedCardTestimonials;

const testimonials = [
  {
    Icon: SiNike,
    description:
      "“I was blown away by UKworkx's Auto Apply service. They really took the time to understand my unique skills and crafted applications that made me stand out. Plus, their CV rewriting was spot-on. I would recommend them”",
    name: "Rajesh",
    title: "Marketing Associate, Nike",
  },
  {
    Icon: SiAtlassian,
    description:
      "I can honestly say that UKworkx was instrumental in helping me land a position at a top financial firm. Their Auto Apply service was a lifesaver, and their expert advice prepared me for even the toughest interview questions. I'm so grateful for their support throughout my job search journey.",
    name: "Muhammad",
    title: "UX Research, Atlassian",
  },
  {
    Icon: SiDribbble,
    description:
      "I have a pretty unique skill set, so I was worried about finding the right job. But UKworkx's Auto Apply service and personalized support were exactly what I needed. They took the time to understand my strengths, provided industry insights, and even helped me prep for interviews.",
    name: "Siddharth",
    title: "Junior Data Analyst, Dribbble",
  },
  {
    Icon: SiGrubhub,
    description:
      "Before UKworkx, I was sending out countless applications in the marketing industry with no luck. But their personalized guidance and mock interviews were a total game-changer. They gave me the confidence and skills I needed to impress employers.",
    name: "Anita",
    title: "Recruitment Specialist, GrubHub",
  },
  {
    Icon: SiKaggle,
    description:
      "As a fresh graduate, I was worried about my lack of work experience. But UKworkx helped me build an impressive portfolio and develop my tech skills. Plus, their Auto Apply feature saved me so much time and effort. I'm now working as a Project Engineering Manager at Kaggle!",
    name: "Rohan",
    title: "Project Engineering Manager, Kaggle",
  },
  {
    Icon: SiSlack,
    description:
      "I'm so grateful for UKworkx's support. Their CV rewriting service was amazing, and they even helped me develop my content creation skills. ",
    name: "Shreya",
    title: "Product Manager, Slack",
  },
];