import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import p1 from "../../images/heroimages/p1.jpeg"
import p2 from "../../images/heroimages/p2.jpeg"
import p3 from "../../images/heroimages/p3.jpeg"
import p4 from "../../images/heroimages/p4.jpeg"
import p5 from "../../images/heroimages/p5.jpeg"
import p6 from "../../images/heroimages/p6.jpeg"
import p7 from "../../images/heroimages/p7.jpeg"
import p8 from "../../images/heroimages/p8.jpeg"
import p9 from "../../images/heroimages/p9.jpeg"
import p10 from "../../images/heroimages/p10.jpeg"
import p11 from "../../images/heroimages/p11.jpeg"
import p12 from "../../images/heroimages/p12.jpeg"
import p13 from "../../images/heroimages/p13.jpeg"
import p14 from "../../images/heroimages/p14.jpeg"
import p15 from "../../images/heroimages/p15.jpeg"
import p16 from "../../images/heroimages/p16.jpeg"

const AnimatedHeading = () => {
  return (
    <h3 className="text-3xl md:text-5xl font-bold leading-tight">
      Unlocking a world of{' '}
      <span className="inline-block text-blue-600">
        visa sponsored
      </span>{' '}
      job opportunities for{' '}
      <span className="inline-block text-green-600">
        international students
      </span>
    </h3>
  );
};

const HeroSection = ({onContactFormOpen}) => {
  return (
    <section className="w-full mb-10 px-8 py-12 grid grid-cols-1 md:grid-cols-2 items-center gap-8 max-w-6xl mx-auto">
      <div>
        <span className="block mb-4 text-xs md:text-sm text-indigo-500 font-medium">
        Empowering Your Future
        </span>
        <AnimatedHeading />
        <p className="text-base md:text-lg text-slate-700 my-4 md:my-6">
        Unlock your potential and land your dream job with our personalized guidance. We're here to help you navigate the job market, sharpen your skills, get relevant work experience and make a lasting impression on employers.
        </p>
        <button className="mb-8 sm:mb-0 bg-indigo-500 text-white font-medium py-2 px-4 rounded transition-all hover:bg-indigo-600 active:scale-95" 
        onClick={() => onContactFormOpen('I want to Book a demo')}>
          Book a Free 15 Minutes Call
        </button>
      </div>
      <ShuffleGrid />
    </section>
  );
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const squareData = [
  {
    id: 1,
    src: p1,
  },
  {
    id: 2,
    src: p2,
  },
  {
    id: 3,
    src: p3,
  },
  {
    id: 4,
    src: p4,
  },
  {
    id: 5,
    src: p5,
  },
  {
    id: 6,
    src: p6,
  },
  {
    id: 7,
    src: p7,
  },
  {
    id: 8,
    src: p8,
  },
  {
    id: 9,
    src: p9,
  },
  {
    id: 10,
    src: p10,
  },
  {
    id: 11,
    src: p11,
  },
  {
    id: 12,
    src: p12,
  },
  {
    id: 13,
    src: p13,
  },
  {
    id: 14,
    src: p14,
  },
  {
    id: 15,
    src: p15,
  },
  {
    id: 16,
    src: p16,
  }
];

const generateSquares = () => {
  return shuffle(squareData).map((sq) => (
    <motion.div
      key={sq.id}
      layout
      transition={{ duration: 1.5, type: "spring" }}
      className="w-full h-full"
      style={{
        backgroundImage: `url(${sq.src})`,
        backgroundSize: "cover",
      }}
    ></motion.div>
  ));
};

const ShuffleGrid = () => {
  const timeoutRef = useRef(null);
  const [squares, setSquares] = useState(generateSquares());

  useEffect(() => {
    shuffleSquares();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  const shuffleSquares = () => {
    setSquares(generateSquares());

    timeoutRef.current = setTimeout(shuffleSquares, 3000);
  };

  return (
    <div className="relative h-[450px] sm:pt-10 pt-20">
      <div className="grid grid-cols-4 grid-rows-4 h-full gap-1">
        {squares.map((sq) => sq)}
      </div>
      <div className="absolute -top-20 sm:-right-20 right-10 z-50">
        <CTAArrow />
      </div>
    </div>
  );
};

const CTAArrow = () => (
  <div className="relative">
    <motion.svg
      width="62"
      height="95"
      viewBox="0 0 62 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="scale-50 sm:scale-75"
      initial={{ scale: 0.7, rotate: 5 }}
      animate={{ scale: 0.75, rotate: 0 }}
      transition={{
        repeat: Infinity,
        repeatType: "mirror",
        duration: 1,
        ease: "easeOut",
      }}
    >
      <path
        d="M46.1381 80.2295C46.7157 61.7854 39.8403 22.9228 7.71748 15.0246"
        stroke="#7D7BE5"
        strokeWidth="3"
      />
      <path
        d="M54.1878 77.2013C50.4013 76.9607 46.1533 78.5579 42.718 79.4945C41.6631 79.7821 40.6209 80.0797 39.5922 80.4129C39.2392 80.5272 39.1847 80.926 38.864 81.0813C38.1317 81.4359 39.2042 82.9094 39.4584 83.2886C41.5188 86.3629 43.6137 89.5084 46.0679 92.4134C47.3639 93.9474 47.8875 92.6745 48.9354 91.5786C49.848 90.6242 50.6189 89.8668 51.2553 88.7753C52.8616 86.0205 53.6705 82.2546 53.9871 79.1621"
        stroke="#7D7BE5"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </motion.svg>
    <span className="text-xs w-fit bg-indigo-500 text-white shadow px-1.5 py-0.5 rounded mt-1 rotate-2 font-light italic">
      Trusted by 200+ International Students
    </span>
  </div>
);

export default HeroSection