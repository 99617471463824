import React, { useRef, useState } from "react";
import { useScroll, motion, useTransform } from "framer-motion";
import {
  FiArrowRight,
  FiAward,
  FiSearch,
  FiCopy,
  FiDatabase,
  FiArrowDown,
  FiBatteryCharging,
  FiPhoneCall,
} from "react-icons/fi";
import InfoBanner from "../banners/InfoBanner";

export default function TimelineFeatures() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const [isInfoBannerOpen, setIsInfoBannerOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleLearnMore = (card) => {
    setSelectedCard(card);
    setIsInfoBannerOpen(true);
  };

  return (
    <>
      <div ref={ref} className="relative">
        {CARDS.map((card, idx) => (
          <Card
            key={card.id}
            card={card}
            scrollYProgress={scrollYProgress}
            position={idx + 1}
            onLearnMore={handleLearnMore}
          />
        ))}
      </div>
      <div className="h-auto bg-black" />
      <InfoBanner
        isOpen={isInfoBannerOpen}
        setIsOpen={setIsInfoBannerOpen}
        content={selectedCard || {}}
        route={selectedCard?.routeTo || "#"}
        visible={selectedCard?.visible || ""} 
      />
    </>
  );
}

const Card = ({ position, card, scrollYProgress, onLearnMore }) => {
  const scaleFromPct = (position - 1) / CARDS.length;
  const y = useTransform(scrollYProgress, [scaleFromPct, 1], [0, -CARD_HEIGHT]);

  const isOddCard = position % 2;

  return (
    <motion.div
      style={{
        height: CARD_HEIGHT,
        y: position === CARDS.length ? undefined : y,
        background: isOddCard ? "black" : "white",
        color: isOddCard ? "white" : "black",
      }}
      className="sticky top-0 flex w-full origin-top flex-col items-center justify-center px-4"
    >
      <card.Icon className="mb-4 text-4xl" />
      <h3 className="mb-6 text-center text-4xl font-semibold md:text-6xl">
        {card.title}
      </h3>
      <p className="mb-8 max-w-lg text-center text-sm md:text-base">
        {card.description}
      </p>
      <button
        onClick={() => onLearnMore(card)}
        className={`flex items-center gap-2 rounded px-6 py-4 text-base font-medium uppercase text-black transition-all hover:-translate-x-0.5 hover:-translate-y-0.5 md:text-lg ${
          card.ctaClasses
        } ${
          isOddCard
            ? "shadow-[4px_4px_0px_white] hover:shadow-[8px_8px_0px_white]"
            : "shadow-[4px_4px_0px_black] hover:shadow-[8px_8px_0px_black]"
        }`}
      >
        <span>Learn more</span>
        <FiArrowRight />
      </button>
    </motion.div>
  );
};

const CARD_HEIGHT = 600;

const CARDS = [
  {
    id: 0,
    Icon: FiArrowDown,
    title: "What makes us different?",
    description:
      "",
    ctaClasses: "bg-amber-300 hidden",
    routeTo: "",
  },
  {
    id: 1,
    Icon: FiBatteryCharging,
    title: "We rewrite your CV's and rebrand your LinkedIn",
    description:
      "Get your resume past applicant tracking systems (ATS) with our CV rewriting and get noticed by recruiters with a professional LinkedIn profile.",
      
    ctaClasses: "bg-violet-300",
    routeTo: "/atschecker",
    visible: "hidden",
    info1 : {"How it works": "Our team of highly skilled professionals will rewrite your CV's and rebrand your LinkedIn profile to ensure that you stand out to recruiters. We will work with you to understand your career goals, strengths, and experiences, and tailor your CV's and LinkedIn profile to highlight your unique qualities. Our team will optimize your CV's for Applicant Tracking Systems (ATS) to ensure that your application passes the initial screening process. Additionally, we will create a professional LinkedIn profile that showcases your skills, experiences, and achievements, making you more attractive to recruiters."},
      info2:{"Why you should use it":"Applicant Tracking Systems (ATS) have become ubiquitous in the recruitment process, serving as the initial filter for candidates. With hundreds or even thousands of applications per position, many companies rely on ATS to identify the most promising candidates efficiently. Unfortunately, this means that many applications are rejected before they ever reach a human recruiter. We will make sure that your CV's are ATS friendly and your LinkedIn profile is professional and attractive to recruiters to get past that diffucult screening stage."},
  },
  {
    id: 2,
    Icon: FiDatabase,
    title: "Get access to 400+ jobs posted weekly",
    description:
      "Access a vast database of visa-sponsored jobs across various industries and companies.",
    ctaClasses: "bg-pink-300",
    info1 : {"How it works?": "Our comprehensive job board aggregates employment opportunities from over 20 prominent job platforms across the United Kingdom, including LinkedIn, Indeed, and Reed.co.uk. The system then cross-references these positions with an extensive database of companies that either possess a visa sponsorship license or have expressed willingness to sponsor visas for eligible candidates."},
      info2:{"Benefits to Job Seekers":"Securing a visa-sponsored position typically involves a lengthy and complex process. This often requires candidates to search multiple job platforms, manually identify companies with sponsorship capabilities, and individually contact recruiters. Our advanced job search tool significantly streamlines this procedure, thereby reducing the time and effort required in the initial stages of the job search. This efficiency allows candidates to redirect their focus towards crucial aspects of their application, such as interview preparation and skill enhancement."},
    routeTo: "https://jobs.ukworkx.com",
    visible: "",
  },
  {
    id: 3,
    Icon: FiCopy,
    title: "Further tailor your already expertly crafted CV's with our AI-powered Resume and Cover Letter Builder for each job you apply by yourself.",
    description:
      "Use our AI-powered tools to instantly create customized, ATS-friendly resumes and cover letters.",
    ctaClasses: "bg-red-300",
    routeTo: "/resume-builder",
    visible: "hidden",
    info1 : {"How it works?": "Our advanced tool revolutionizes the job application process by generating an ATS-friendly resume tailored to your target job description with a single click. Leveraging the cutting-edge Claude Opus AI model and our proprietary algorithms, it produces a customized resume in under a minute. Additionally, the tool crafts a bespoke cover letter, streamlining your application process and significantly enhancing your chances of success."},
      info2:{"Benefits to Job Seekers":"This innovative solution addresses a common challenge faced by job seekers: resume-job description misalignment. Many applications are rejected due to missing keywords, tools, or skills in the applicant's CV. Our Resume Maker intelligently modifies your summary, job details (excluding titles), and other relevant sections to align perfectly with the job requirements. This strategic optimization gives you a competitive edge in the application process, increasing your visibility to recruiters and improving your chances of securing an interview."},
  },
  {
    id: 4,
    Icon: FiAward,
    title: "It's not you, it's us. Our experts will send job applications on your behalf. You just sit back and relax.",
    description:
      "Let our team handle job applications on your behalf, focusing on positions with high visa sponsorship potential.",
    ctaClasses: "bg-amber-300",
    routeTo: "/job-application-service",
    visible: "hidden",
    info1 : {"How it works?": "Our team of professionals will conduct a thorough market analysis to identify job opportunities that align with your career goals and visa sponsorship requirements. We will then apply to these positions on your behalf, tailoring your CV and cover letter to each job description. Our team will also follow up with recruiters, schedule interviews, and provide guidance throughout the application process, ensuring that you are well-prepared for each stage of the recruitment process."},
      info2:{"Benefits to Job Seekers":"The job application process can be time-consuming and overwhelming, especially for international students seeking visa-sponsored positions. Our Job Application Service alleviates this burden by handling the entire application process on your behalf. This service not only saves you time and effort but also increases your chances of securing interviews and job offers. By leveraging our expertise and industry connections, you can focus on preparing for interviews and enhancing your skills, confident that your applications are in capable hands."},
  },
  {
    id: 5,
    Icon: FiPhoneCall,
    title: "Say goodbye to interview jitters. Our experts will prepare you for interviews and provide feedback on your performance.",
    description:
      "Receive personalized interview preparation and feedback from our team of experts.",
    ctaClasses: "bg-amber-300",
    routeTo: "",
    visible: "hidden",
    info1 : {"How it works?": "Our experts will conduct mock interviews with you, simulating real-world scenarios to help you prepare for your upcoming interviews. We will provide feedback on your performance, highlighting areas for improvement and offering valuable insights to enhance your interview skills. Our team will also share tips and strategies to help you succeed in interviews, boosting your confidence and increasing your chances of securing job offers."},
      info2:{"Benefits to Job Seekers":"You may have the skills and experience required for a job, but if you struggle with interviews, you may not be able to showcase your full potential to employers. Our Interview Preparation Service equips you with the tools and techniques needed to excel in interviews, ensuring that you make a positive impression on recruiters. By practicing with our experts and receiving constructive feedback, you can refine your interview skills, overcome nervousness, and present yourself as a confident and competent candidate."},
  },
  {
    id: 6,
    Icon: FiSearch,
    title: "Not sure if you need a professional's help? Try our ATS and Resume Checker tool completely free of cost to see where you stand.",
    description:
      "Get your resume past applicant tracking systems (ATS) with our instant CV review and scoring service.",
      
    ctaClasses: "bg-violet-300",
    routeTo: "/atschecker",
    visible: "",
    info1 : {"How it works": "Our ATS tool harnesses the power of proprietary algorithms in conjunction with Claude AI (Opus), the most advanced AI model available, to perform a comprehensive analysis of your resume. The system meticulously evaluates your document's readability across various Applicant Tracking Systems (ATS), including industry standards like Workday*, ensuring optimal parsing. Following this thorough analysis, you'll receive a numerical score indicating your resume's ATS compatibility, along with a detailed report. This report highlights potential improvements in key areas such as your education section, professional summary, bullet points, filler words, and other critical resume components. These valuable insights empower you to refine your CV, maximizing its effectiveness in ATS environments."},
      info2:{"Why you should use it":"Applicant Tracking Systems (ATS) have become ubiquitous in the recruitment process, serving as the initial filter for candidates. With hundreds or even thousands of applications per position, many companies rely on ATS to identify the most promising candidates efficiently. Unfortunately, this means that many applications are rejected before they ever reach a human recruiter. Our ATS tool provides you with a competitive edge by ensuring your resume is optimized for ATS readability, increasing the likelihood of your application passing initial screening stages, and improving your chances of getting your resume in front of actual recruiters. What sets our tool apart is its user-friendly interface, free access for all international students, and its specific design for international students studying in the UK. By utilizing our ATS checker, you're taking a proactive step to enhance your job application success rate in a highly competitive market."},
  },
];