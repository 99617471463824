import React from 'react'
import { Link } from 'react-router-dom'
import video from "../../videos/reviewvid1.mp4"
import videochetan from "../../videos/chetan.mp4"
import videoshweta from "../../videos/shweta.mp4"
import videosonal from "../../videos/sonalrawat.mp4"
import videosoniya from "../../videos/soniya.mp4"

function VideoTestimonials() {
  return (
    <>
    {/* dharmin */}
    <div className='flex sm:flex-row flex-col justify-around mb-5'>
      <div className='font-extrabold text-center text-2xl pb-5 sm:text-4xl self-center'>
    <h1 className='font-extrabold text-center text-2xl pb-5 sm:text-4xl italic self-center'>Meet Dharmin, Ex-Client of UKworkx &#8594;</h1>
    </div>
    <video className="h-80 w-auto rounded-lg" controls autoPlay muted>
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    </div>
    {/* dharmin */}
    <div className='flex sm:flex-row flex-col justify-around mb-5'>
      <div className='font-extrabold text-center text-2xl pb-5 sm:text-4xl self-center'>
    <h1 className='font-extrabold text-center text-2xl pb-5 sm:text-4xl italic self-center'>Meet Chetan, Ex-Client of UKworkx &#8594;</h1>
    </div>
    <video className="h-80 w-auto rounded-lg" controls autoPlay muted>
      <source src={videochetan} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    </div>
    {/* dharmin */}
    <div className='flex sm:flex-row flex-col justify-around mb-5'>
      <div className='font-extrabold text-center text-2xl pb-5 sm:text-4xl self-center'>
    <h1 className='font-extrabold text-center text-2xl pb-5 sm:text-4xl italic self-center'>Meet Sonal, Ex-Client of UKworkx &#8594;</h1>
    </div>
    <video className="h-80 w-auto rounded-lg" controls autoPlay muted>
      <source src={videosonal} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    </div>
    {/* dharmin */}
    <div className='flex sm:flex-row flex-col justify-around mb-5'>
      <div className='font-extrabold text-center text-2xl pb-5 sm:text-4xl self-center'>
    <h1 className='font-extrabold text-center text-2xl pb-5 sm:text-4xl italic self-center'>Meet Soniya, Ex-Client of UKworkx &#8594;</h1>
    </div>
    <video className="h-80 w-auto rounded-lg" controls autoPlay muted>
      <source src={videosoniya} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    </div>
    {/* dharmin */}
    <div className='flex sm:flex-row flex-col justify-around mb-5'>
      <div className='font-extrabold text-center text-2xl pb-5 sm:text-4xl self-center'>
    <h1 className='font-extrabold text-center text-2xl pb-5 italic sm:text-4xl self-center'>Meet Shweta, Ex-Client of UKworkx &#8594;</h1>
    <Link className='sm:block cursor-pointer self-center text-indigo-600 font-bold underline sm:text-3xl pt-5' to='/casestudies'>View more detailed case studies</Link>
    </div>
    <video className="h-80 w-auto rounded-lg" controls autoPlay muted>
      <source src={videoshweta} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <Link className='sm:hidden cursor-pointer self-center text-indigo-600 font-bold underline sm:text-3xl pt-5' to='/casestudies'>View more detailed case studies</Link>
    </div>
    </>
  )
}

export default VideoTestimonials