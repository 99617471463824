import {
  PhotoIcon,
  DocumentTextIcon,
  StarIcon,
  DocumentIcon,
} from "@heroicons/react/24/solid";
import React, { useState } from "react";
import Navbar from "../navbar/Navbar";
import facePhoto from "../../images/faces.png";
import documentIcon from "../../images/docs.png";
import suggestionIcon from "../../images/suggestion.png";
import recommendationIcon from "../../images/recommendation.png";
import { Link,animateScroll } from "react-scroll";
import Testimonials from "../testimonials/Testimonials"
export default function AtsChecker({ onContactFormOpen }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingText, setLoadingText] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Please select a PDF or DOCX file.");
    }
  };

  function ScrollToTop () {
    animateScroll.scrollToTop({
      duration: 800,
      smooth: "easeInOutQuart",
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    ScrollToTop();

    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("resume", selectedFile);

    try {
      // Start the loading progress simulation
      const totalDuration = 20000; // 25 seconds in milliseconds
      const interval = 200; // Update progress every 250 milliseconds
      const totalIntervals = totalDuration / interval;
      let i = 0;

      // Create a promise for the API request
      const apiRequest = fetch("/resumes/upload", {
        method: "POST",
        body: formData,
      });

      // Continue the loading progress simulation until the response is received or the simulation ends
      const simulateProgress = () => {
        if (i <= totalIntervals) {
          const progress = Math.round((i / totalIntervals) * 100);
          setLoadingProgress(progress);

          if (progress <= 25) {
            setLoadingText("Uploading your CV");
          } else if (progress <= 60) {
            setLoadingText(
              "We are checking your CV against Greenhouse/Workday/Ukworkx ATS"
            );
          } else {
            setLoadingText("Our AI and ML algorithms are analyzing your CV");
          }

          i++;
          setTimeout(simulateProgress, interval);
        }
      };

      simulateProgress();

      const response = await apiRequest;

      if (response.ok) {
        const data = await response.json();
        // Redirect to the results page with the resume data
        window.location.href = `/atschecker/result?name=${encodeURIComponent(
          data.resume.name
        )}&email=${encodeURIComponent(data.resume.email)}&score=${
          data.resume.score
        }&submissionDate=${encodeURIComponent(
          data.resume.submissionDate
        )}&criticism=${encodeURIComponent(
          data.resume.criticism
        )}&detailedResponse=${encodeURIComponent(
          JSON.stringify(data.resume.detailedResponse)
        )}`;
      } else if (response.status === 409) {
        const data = await response.json();
        // Redirect to the results page with the existing resume data
        window.location.href = `/atschecker/result?name=${encodeURIComponent(
          data.existingResume.name
        )}&email=${encodeURIComponent(data.existingResume.email)}&score=${
          data.existingResume.score
        }&submissionDate=${encodeURIComponent(
          data.existingResume.submissionDate
        )}&criticism=${encodeURIComponent(
          data.existingResume.criticism
        )}&detailedResponse=${encodeURIComponent(
          JSON.stringify(data.existingResume.detailedResponse)
        )}`;
      } else {
        alert("Error uploading file");
      }
    } catch (error) {
      alert("Error uploading file");
    }

    setIsLoading(false);
  };

  return (
    <div>
      <div className="">
        <Navbar />
      </div>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <div className="w-full max-w-md">
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="mb-4">
                <div className="flex items-center">
                  {loadingProgress <= 25 ? (
                    <span className="loading loading-dots loading:sm sm:loading-lg"></span>
                  ) : loadingProgress <= 60 ? (
                    <span className="loading loading-dots loading-sm sm:loading-lg"></span>
                  ) : (
                    <span className="loading loading-dots loading-sm sm:loading-lg"></span>
                  )}
                  <span className="pl-5 text-sm sm:text-lg font-semibold">
                    {loadingText}
                  </span>
                </div>
              </div>
              <div className="relative pt-1">
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200">
                  <div
                    style={{ width: `${loadingProgress}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500 transition-all duration-500 ease-in-out"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="relative space-y-12 mx-4 sm:mx-10">
            <div className="border-b border-gray-900/10 pt-10 pb-12">
              <div className="col-span-full">
                <h1
                  htmlFor="cover-photo"
                  className="font-black italic font-serif text-2xl text-center leading-8 mb-8 text-gray-900"
                >
                  Built for <s>Everyone</s>{" "}
                  <span className="text-green-600">international Students</span>
                </h1>
                <h1
                  htmlFor="cover-photo"
                  className="text-2xl text-center font-bold leading-8 text-gray-900"
                >
                  Land more interviews with our{" "}
                  <span className="text-green-600">Free</span> resume analyzer
                  and ATS checker
                </h1>
                <p className="text-gray-900 text-center text-sm pt-5 pb-5">
                  Our free ATS Resume Checker analyzes your resume and
                  identifies important keywords and skills missing from your
                  resume in just a minute! Over 93% of our clients mentioned that our tool was one of the key elements in their job search.{" "}
                </p>
                <div className="stats shadow flex justify-center text-center">
                  <div className="stat">
                    <div className="stat-title">Total Resume's Analyzed</div>
                    <div className="stat-value">7100+</div>
                    <div className="stat-desc">(We try to update this number every month)</div>
                  </div>
                </div>
                <div className="mt-5 flex justify-center">
                  <Link
                    type="button"
                    to="uploadBox"
                    smooth={true}
                    duration={800}
                    className="cursor-pointer text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-12 py-2.5 text-center me-2 "
                  >
                    Get Started - It's 100% Free! ✨
                  </Link>
                </div>
                <p className="text-gray-900 text-center text-[10px] mb-5">
                  No credit card/No sign-up required
                </p>
                <div className="flex justify-center space-x-4">
                  <img
                    src={facePhoto}
                    alt="Faces"
                    className="w-40 h-8 rounded-full object-cover"
                  />
                  <div className="flex flex-col items-start mb-5">
                    <div className="flex space-x-1">
                      {[...Array(5)].map((_, index) => (
                        <StarIcon
                          key={index}
                          className="w-3 h-3 sm:w-4 sm:h-4 text-yellow-500"
                        />
                      ))}
                    </div>
                    <span className="text-gray-600 text-[8px] sm:text-sm">
                      Suggested by international students like you!
                    </span>
                  </div>
                </div>
                <div className="text-center my-5 mb-10">
                  <h2 className="text-2xl font-bold mb-6">How it works</h2>
                  <div className="flex flex-col md:flex-row justify-around">
                    <div className="flex-1 px-4 mb-8 md:mb-0">
                      <img
                        src={documentIcon}
                        alt="Upload Icon"
                        className="w-12 h-12 mx-auto mb-4"
                      />
                      <h3 className="text-sm font-bold mb-2 sm:text-lg">
                        Upload Your Resume
                      </h3>
                      <p className="text-[12px] sm:text-base">
                        Easily submit your resume for a thorough analysis.
                      </p>
                    </div>
                    <div className="flex-1 px-4 mb-8 md:mb-0">
                      <img
                        src={recommendationIcon}
                        alt="Copy and Paste Icon"
                        className="w-12 h-12 mx-auto mb-4"
                      />
                      <h3 className="text-sm font-bold mb-2 sm:text-lg">
                        Let our AI and ML algorithms Analyze Your Resume
                      </h3>
                      <p className="text-[12px] sm:text-base">
                        It takes less than a minute.
                      </p>
                    </div>
                    <div className="flex-1 px-4">
                      <img
                        src={suggestionIcon}
                        alt="Recommendations Icon"
                        className="w-12 h-12 mx-auto mb-4"
                      />
                      <h3 className="text-sm font-bold mb-2 sm:text-lg">
                        Get Recommendations to Optimize Resume
                      </h3>
                      <p className="text-[12px] sm:text-base">
                        Get tailored tips for a stronger resume instantly.
                      </p>
                    </div>
                  </div>
                </div>
                <h1
                  htmlFor="cover-photo"
                  className="text-xl text-center font-bold leading-8 text-gray-900"
                >
                  Your resume's ATS score and a full analysis report for{" "}
                  <span className="text-green-600">Free</span>
                </h1>
                <p className="text-gray-900 text-center text-xs pb-5">
                  Upload your resume
                </p>
                <div className="mt-2 flex justify-center rounded-lg border-4 border-dashed border-green-600/25 px-6 py-3">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 hover:text-green-500"
                    id="uploadBox"
                  >
                    <div className="text-center">
                      <PhotoIcon
                        className="mx-auto h-12 w-12 text-gray-300"
                        aria-hidden="true"
                      />
                      <div className="mt-4 flex flex-col items-center text-sm leading-6 text-gray-600">
                        <span className="text-gray-900 font-bold">
                          Tap here to upload your Resume/CV
                        </span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          accept=".pdf,.docx"
                          onChange={handleFileChange}
                          className="sr-only"
                        />
                        <p className="pl-1 text-[10px] sm:text-base">
                          Choose a doc, docx or pdf file up to 5MB
                        </p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            {selectedFile && (
              <div className="flex items-center justify-center">
                {selectedFile.type === "application/pdf" ? (
                  <DocumentIcon className="h-6 w-6 text-red-500 mr-2" />
                ) : (
                  <DocumentTextIcon className="h-6 w-6 text-blue-500 mr-2" />
                )}
                <span>{selectedFile.name}</span>
              </div>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-x-6 gap-y-4">
              <button 
                type="submit"
                className={`text-white font-medium rounded-lg text-sm px-24 py-2.5 text-center me-2 mb-2 ${
                  selectedFile
                    ? "bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
                    : "bg-gray-400 cursor-not-allowed"
                }`}
                disabled={!selectedFile}
              >
                Get Result
              </button>
            </div>
          </form>
        </>
      )}
      <Testimonials/>
    </div>
  );
}
