import React, { useState } from "react";
import useMeasure from "react-use-measure";
import {
  useDragControls,
  useMotionValue,
  useAnimate,
  motion,
} from "framer-motion";

export default function InfoBanner({ isOpen, setIsOpen, content, route,visible }) {
  return (
    <>
      {isOpen && (
        <DragCloseDrawer open={isOpen} setOpen={setIsOpen} route={route}>
          <div className="mx-auto max-w-2xl space-y-6 text-neutral-400">
            <h2 className="text-4xl font-bold text-center text-neutral-200">
              {content.title}
            </h2>
            <div className="text-center">
              <p>{content.description}</p>
            
            
            {content.info1 && Object.entries(content.info1).map(([key, value]) => (
              <div key={key} className="mt-4">
                <h3 className="text-2xl font-semibold text-neutral-300">{key}</h3>
                <p className="mt-2">{value}</p>
              </div>
            ))}
            
            {content.info2 && Object.entries(content.info2).map(([key, value]) => (
              <div key={key} className="mt-4">
                <h3 className="text-2xl font-semibold text-neutral-300">{key}</h3>
                <p className="mt-2">{value}</p>
              </div>
            ))}
          </div>
          </div>
          <div className="flex items-center justify-center pt-10">
            <a
              href={route}
              className={`px-6 py-2 font-medium bg-indigo-500 text-white w-fit transition-all shadow-[3px_3px_0px_black] hover:shadow-none hover:translate-x-[3px] hover:translate-y-[3px] ${visible}`}
            >
              Try Now
            </a>
          </div>
        </DragCloseDrawer>
      )}
    </>
  );
}

const DragCloseDrawer = ({ open, setOpen, children, route }) => {
  const [scope, animate] = useAnimate();
  const [drawerRef, { height }] = useMeasure();

  const y = useMotionValue(0);
  const controls = useDragControls();

  const handleClose = async () => {
    animate(scope.current, {
      opacity: [1, 0],
    });

    const yStart = typeof y.get() === "number" ? y.get() : 0;

    await animate("#drawer", {
      y: [yStart, height],
    });

    setOpen(false);
  };

  return (
    <motion.div
      ref={scope}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={handleClose}
      className="fixed inset-0 z-50 bg-neutral-950/70"
    >
      <motion.div
        id="drawer"
        ref={drawerRef}
        onClick={(e) => e.stopPropagation()}
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        transition={{
          ease: "easeInOut",
        }}
        className="absolute bottom-0 h-[75vh] w-full overflow-hidden rounded-t-3xl bg-neutral-900"
        style={{ y }}
        drag="y"
        dragControls={controls}
        onDragEnd={() => {
          if (y.get() >= 100) {
            handleClose();
          }
        }}
        dragListener={false}
        dragConstraints={{
          top: 0,
          bottom: 0,
        }}
        dragElastic={{
          top: 0,
          bottom: 0.5,
        }}
      >
        <div className="absolute left-0 right-0 top-0 z-10 flex justify-center bg-neutral-900 p-4">
          <button
            onPointerDown={(e) => {
              controls.start(e);
            }}
            className="h-2 w-14 cursor-grab touch-none rounded-full bg-neutral-700 active:cursor-grabbing"
          ></button>
        </div>
        <div className="relative z-0 h-full overflow-y-scroll p-4 pt-12">
          {children}
        </div>
      </motion.div>
    </motion.div>
  );
};